import React from 'react'
import { RestaurantLocationProps } from './RestaurantLocation'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

export default function Cta({
  locations
}: {
  locations: Array<RestaurantLocationProps>
}) {
  // Scroll to bottom if more than one location
  const shouldScroll = locations.length > 1
  const href = shouldScroll ? '#locations' : locations[0].pickupUrl
  const LinkComponent = shouldScroll ? 'a' : OutboundLink

  if (!href) {
    return <></>
  }

  return (
    <LinkComponent
      href={href}
      className="inline-block bg-primary rounded-full text-white py-5 px-16 font-bold text-xl"
    >
      Order Pickup
    </LinkComponent>
  )
}
